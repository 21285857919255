<template>
  <!-- <v-container> -->
  <!-- <v-row class="d-flex align-center justify-center mt-16">
      <v-col>
        <div class="d-flex justify-center">
          <img src="../assets/indogrosir.png" class="mx-auto">
        </div>
        <h2 class="d-flex justify-center">E-Bupot Indogrosir</h2>
      </v-col>
    </v-row>
    <v-row class="d-flex align-center justify-center">
      <v-col>
        <v-card class="mx-auto justify-center" max-width="344" outlined>
          <v-card-title class="justify-center indigo darken-4 white--text">
            <span class="text-h6 font-weight-light">Login</span>
          </v-card-title>
          <v-card-text>
            <v-form ref="form" lazy-validation @submit.prevent="userLogin">
              <v-text-field v-model="form.username" label="Username" required></v-text-field>

              <v-text-field v-model="form.password" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" label="Password"
                :type="show1 ? 'text' : 'password'" required @click:append="show1 = !show1"></v-text-field>
              <v-btn color="success" class="mr-4" type="submit" :loading="loading" :disabled="submitting">
                Login
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row> -->
  <!-- </v-container> -->
  <v-container fluid style="height: 100%;">
    <v-layout row wrap fill-height fluid>
      <!-- xs12 and sm12 to make it responsive = 12 columns on mobile and 6 columns from medium to XL layouts -->
      <v-flex xs12 sm12 md6 style="background-color:white;" class="d-flex justify-center align-center">
        <v-img src="../assets/login8.jpg" height="100%">

        </v-img>
      </v-flex>
      <v-flex xs12 sm12 md6 style="background-color:white;" class="d-flex justify-center align-center">
        <v-container>
          <v-row class="d-flex align-center">
            <v-container class="mx-auto my-auto pa-7 pa-sm-12">
              <div class="pa-7 pa-sm-12 d-flex">
                <v-row>
                  <v-col align="center">
                    <img src="../assets/indogrosir.png" class="mx-auto">
                    <h2 class="text-h4 d-flex justify-center mb-5 font-weight-bold text-grey-darken-1">Aplikasi E-Bupot
                      Indogrosir</h2>
                    <v-card class="mx-auto" width="75%" outlined>
                      <v-card-title class="justify-center  blue white--text">
                        <span class="text-h6">Login</span>
                      </v-card-title>
                      <v-card-text>
                        <!-- <span class="text-h6 font-weight-bold">E-Bupot Indogrosir</span> -->
                        <v-form ref="form" lazy-validation @submit.prevent="userLogin" class="my-3">
                          <v-text-field v-model="form.username" label="Username" required outlined></v-text-field>

                          <v-text-field v-model="form.password" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                            label="Password" :type="show1 ? 'text' : 'password'" required @click:append="show1 = !show1"
                            outlined>
                          </v-text-field>
                          <v-btn color="success" class="mx-auto" type="submit" :loading="loading" :disabled="submitting">
                            Login
                          </v-btn>
                        </v-form>
                      </v-card-text>
                    </v-card>

                  </v-col>
                </v-row>
              </div>
            </v-container>
          </v-row>
        </v-container>
        <!-- <v-row class="d-flex align-center justify-center mt-16">
          <v-col>
            <div class="d-flex justify-center">
              <img src="../assets/indogrosir.png" class="mx-auto">
            </div>
          </v-col>
        </v-row>
        <v-row class="d-flex align-center justify-center">
          <v-col>
            <v-card class="mx-auto justify-center" max-width="344" outlined>
              <v-card-title class="justify-center indigo darken-4 white--text">
                <span class="text-h6 font-weight-light">Login</span>
              </v-card-title>
              <v-card-text>
                <v-form ref="form" lazy-validation @submit.prevent="userLogin">
                  <v-text-field v-model="form.username" label="Username" required></v-text-field>

                  <v-text-field v-model="form.password" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    label="Password" :type="show1 ? 'text' : 'password'" required @click:append="show1 = !show1">
                  </v-text-field>
                  <v-btn color="success" class="mr-4" type="submit" :loading="loading" :disabled="submitting">
                    Login
                  </v-btn>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row> -->
      </v-flex>
    </v-layout>
    <v-dialog v-model="dialog" width="60%" height="60%" persistent overlay-color="white">
      <v-card height="100%">
        <v-toolbar color="primary" dark flat>

          <v-toolbar-title class="text-h6">PENGUMUMAN</v-toolbar-title>
          <v-spacer></v-spacer>


          <template v-slot:extension>
            <v-tabs v-model="tab" align-with-title>
              <v-tabs-slider color="yellow"></v-tabs-slider>

              <v-tab v-for="item in items" :key="item.ID_PENGUMUMAN" @click="changeTab(item)">
                {{ item }}
              </v-tab>
            </v-tabs>
          </template>
        </v-toolbar>

        <v-tabs-items v-model="tab">
          <v-tab-item v-for="item in items" :key="item.ID_PENGUMUMAN">
            <v-card flat>
              <!-- {{ this.nama_file }} -->
              <b><v-card-text class="text-h6" v-html="tanggal_pengumuman"></v-card-text></b>
              <v-card-text v-html="text"></v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
        <v-divider color="black" class="mx-5"></v-divider>
        <v-card-actions>
          <v-btn v-if="this.nama_file !== null" color="green" dark @click="downloadFile()" :loading=loadingDownload>
            Download
          </v-btn>
          <p class="ma-auto mx-1 text-caption">{{ this.nama_file }}</p>
          <v-spacer></v-spacer>

          <v-btn color="error" dark @click="redirectPage()">
            Close
          </v-btn>
        </v-card-actions>
        <!-- <v-card-actions>
          <p class="ma-auto mx-1">{{ this.nama_file }}</p>
          <v-spacer></v-spacer>

        </v-card-actions> -->
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
export default {
  name: 'login',
  data() {
    return {
      show1: false,
      form: {
        username: "",
        password: "",
      },
      errors: null,
      currentAlertCounter: 10,
      loading: false,
      loadingDownload: false,
      submitting: false,
      dialog: false,
      tab: null,
      items: [],
      data_pengumuman_lengkap: [],
      id_pengumuman_active: '',
      nama_file: '',
      tanggal_pengumuman: ''
      // text: '<p>TEST 1</p><p><em>TESTT</em></p><p><br></p><p><em><u>UNDERLINE\'</u></em></p><p><br></p><p><br></p><p><em style="color: rgb(102, 185, 102);"><u>AWSSDA</u></em></p>'
    };
  },
  methods: {
    formatDateIdn(date) {
      // console.log(date);
      var date_awal = new Date(date);
      const formatedDate = date_awal.toLocaleDateString('id-ID', {
        day: 'numeric', month: 'long', year: 'numeric'
      });
      if (formatedDate === 'Invalid Date') {
        return '';
      } else {
        return formatedDate;
      }
    },
    changeTab(item) {
      // console.log(item);
      // console.log()
      // console.log(this.data_pengumuman_lengkap);
      var cari_isi_pengumuman = this.data_pengumuman_lengkap.find(element => element.JUDUL_PENGUMUMAN === item);
      // console.log(cari_isi_pengumuman)
      this.text = cari_isi_pengumuman.ISI_PENGUMUMAN;
      this.id_pengumuman_active = cari_isi_pengumuman.ID_PENGUMUMAN;
      this.nama_file = cari_isi_pengumuman.REALNAME;
      this.tanggal_pengumuman = this.formatDateIdn(cari_isi_pengumuman.START_DATE);
    },
    redirectPage() {
      if (this.user.role_id == 1) {
        this.$root.$emit('triggerMenuAdmin');
        this.$router.push({ name: "TrxPageKasir" }).catch(() => { });
      } else if (this.user.role_id == 2) {
        this.$router.push({ name: "TrxPageValidator" }).catch(() => { });
      } else {
        this.$root.$emit('triggerMenu');
        this.$router.push('/pilihcustomer');
      }
    },
    async getPengumuman() {
      var data_pengumuman = [];
      var judul_pengumuman = [];
      await axios({
        method: 'post',
        url: 'getDataPengumuman'
      })
        .then((result) => {
          data_pengumuman = result.data.data;
        })
        .catch(() => {
        })
      // console.log(data_pengumuman.length);
      if (data_pengumuman.length > 0) {
        this.data_pengumuman_lengkap = data_pengumuman;
        data_pengumuman.forEach(function (arrayItem) {
          // this.items = data_pengumuman;
          judul_pengumuman.push(arrayItem.JUDUL_PENGUMUMAN);
        })
        this.items = judul_pengumuman;
        this.text = data_pengumuman[0].ISI_PENGUMUMAN;
        this.id_pengumuman_active = data_pengumuman[0].ID_PENGUMUMAN;
        this.nama_file = data_pengumuman[0].REALNAME;
        this.tanggal_pengumuman = this.formatDateIdn(data_pengumuman[0].START_DATE_LENGKAP);
        this.dialog = true;
      } else {
        if (this.user.role_id == 1) {
          this.$root.$emit('triggerMenuAdmin');
          this.$router.push({ name: "TrxPageKasir" }).catch(() => { });
        } else if (this.user.role_id == 2) {
          this.$router.push({ name: "TrxPageValidator" }).catch(() => { });
        } else {
          this.$root.$emit('triggerMenu');
          this.$router.push('/pilihcustomer');
        }
      }
      // this.
    },
    downloadFile() {
      this.loadingDownload = true;
      // axios({
      //     url: 'http://localhost:8000/demo.pdf', // File URL Goes Here
      //     method: 'GET',
      //     responseType: 'blob',
      // }).then((res) => {
      //      var FILE = window.URL.createObjectURL(new Blob([res.data]));

      //      var docUrl = document.createElement('x');
      //      docUrl.href = FILE;
      //      docUrl.setAttribute('download', 'file.pdf');
      //      document.body.appendChild(docUrl);
      //      docUrl.click();
      // });
      axios({
        method: 'post',
        url: 'getDownload',
        data: {
          id_pengumuman: this.id_pengumuman_active
        },
        responseType: "blob"
      })
        .then((result) => {
          const contentDisposition = result.headers['content-disposition'];

          var fileName = contentDisposition.split(";");
          fileName = fileName[1].replace(' filename=', '');
          // console.log(fileName)
          const url = window.URL.createObjectURL(new Blob([result.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          this.loadingDownload = false;
          // const downloadData = window.URL.createObjectURL(new Blob([res.data]));
          // const downloadLink = document.createElement("a");
          // // const getType = downloadData.type;
          // // const getTypeSplit = getType.split("/");
          // downloadLink.href = URL.createObjectURL(new Blob([downloadData]));
          // // downloadLink.setAttribute("download", `file.${getTypeSplit[1]}`);
          // downloadLink.setAttribute("download", `Test.pdf`);
          // downloadLink.style.display = "none";
          // document.body.appendChild(downloadLink);
          // downloadLink.click();
          // document.body.removeChild(downloadLink);
        })
    },
    userLogin() {
      this.submitting = true;
      this.loading = true;
      this.$toast.open({
        position: 'top-right',
        message: 'Sedang Login...',
        type: 'info'
      });
      this.$store.dispatch("login", this.form).then(() => {
        this.$store.dispatch("get_user", this.form).then(() => {
          // if (this.user.role_id != 3) {
          this.getPengumuman();
          axios({
            method: 'post',
            url: 'showAlertOutstanding',
            data: {
              user_id: this.user.id
            }
          })
            .then((result) => {
              // console.log(result.data.data);
              if (result.data.data > 0) {
                this.$swal.fire(
                  'Info!',
                  'Terdapat Kwitansi yang status penyerahan BuPot-nya masih "Outstanding".Untuk informasi lengkap terkait "Outstanding" tsb., silahkan akses dan cetak report "Outstanding"!',
                  'info'
                )
              }
            })
            .catch(() => {
            })
          this.$toast.open({
            position: 'top-right',
            message: 'Berhasil Login!',
            type: 'success'
          });
          this.$session.start();
          // console.log(this.user);
          // alert(JSON.stringify(response));
          this.$root.$emit('eventing');
          // this.$emit('changeUsername', 'TEST123');
          // if (this.user.role_id == 1) {
          //   this.$root.$emit('triggerMenuAdmin');
          //   this.$router.push({ name: "TrxPageKasir" }).catch(() => { });
          // } else if (this.user.role_id == 2) {
          //   this.$router.push({ name: "TrxPageValidator" }).catch(() => { });
          // } else {
          //   this.$root.$emit('triggerMenu');
          //   this.$router.push('/pilihcustomer');
          // }
          this.submitting = false;
          this.loading = false;
          // } else {
          // this.$session.destroy();
          // this.$store.dispatch('logout').then(() =>
          //   this.$toast.open({
          //     position: 'top-right',
          //     message: 'Berhasil Logout!',
          //     type: 'success'
          //   }))
          // this.$swal.fire(
          //   'Info!',
          //   'Login Supplier bisa dipakai mulai tanggal 1 Juli 2023',
          //   'info'
          // )
          // this.submitting = false;
          // this.loading = false;
          // }
        })
          .catch(error => {
            console.log(error.status);
            this.$swal.fire(
              'Gagal Login!',
              'Username atau password salah!',
              'warning'
            )
            this.submitting = false;
            this.loading = false;
          })
      })
        .catch(error => {
          // console.log(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
          if (error.response.status === 401) {
            this.$swal.fire(
              'Gagal Login!',
              'Username atau password salah!',
              'warning'
            )
          } else {
            this.$swal.fire(
              'Gagal Login!',
              error.response.data.message,
              'warning'
            )
          }
          this.submitting = false;
          this.loading = false;
        })
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
    })
  },
};
</script>

